import Vue from 'vue';
import VueRouter from 'vue-router'
import AuthService from "@/services/auth.js";

Vue.use(require('vue-cookies'));
Vue.use(VueRouter);

let router = new VueRouter({
  routes: [
    {
      path: '/',
      name: 'CcMain',
      component: () => import("@/views/CcMain")
    },
    {
      path: '/login',
      name: 'CcLogin',
      component: () => import("@/views/CcLogin")
    },
    {
      path: '/em_andamento',
      name: 'CcOngoing',
      component: () => import("@/views/CcOngoing")
    },
    {
      path: '/test',
      name: 'CcTest',
      component: () => import("@/views/CcTest")
    },
    {
      path: '/history',
      name: 'CcHistory',
      component: () => import("@/views/CcHistory")
    },
    {
      path: '/dashboard',
      name: 'CcDashboard',
      component: () => import("@/views/CcDashboard")
    },
    {
      path: '/tabela',
      name: 'CcSLA',
      component: () => import("@/views/CcSLA")
    },
    {
      path: '/audit',
      name: 'CcAudit',
      component: () => import("@/views/CcAudit")
    },
    {
      path: '/dashboard/per_state',
      name: 'CcDashboardState',
      component: () => import("@/views/CcDashboardState")
    },
    {
      path: '/novo_chamado',
      name: 'CcNewTicket',
      component: () => import("@/views/CcNewTicket")
    }
  ]
});

router.beforeEach((to, from, next) => {
  let user_info = AuthService.getUserInformation();

  if (user_info.id == null && to.name != 'CcLogin') {
    next("/login")
  } else {
    // Percorre os components daquela rota para carregar o titulo da pagina
    next();
  }
});

export default router;