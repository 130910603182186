<template>
    <router-view />
</template>

<script>

export default {
    name: "App",
    components: {}
};
</script>

<style lang="scss">
@import "./assets/styles/index.scss";
</style>


<style>
@import "./assets/custom/custom.css";
@import "./assets/style.css";

body {
    font-family: Roboto, -apple-system, system-ui, BlinkMacSystemFont, Segoe UI,
        Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif !important;
}

/* #app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
} */
</style>

